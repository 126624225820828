import detailTemplate from './detail.template.html';
import {HEADER_SIZE, FOOTER_SIZE} from './constants.js';
import {quadIn} from 'eases';
const TRANSITION_TIME = 0.5 * 1000;
const SLIDER_SETTLE_TIME = 0.33 * 1000;
const FLICK_TIME_THRESH = 200;
const FLICK_MOVE_THRESH = 30;
/**
 * lerp
 * @param {number} v0 - start value
 * @param {number} v1 - end value
 * @param {number} t - delta
 * @return {number} lerped value
 */
function lerp(v0, v1, t) {
  return v0*(1-t)+v1*t;
}

/**
 * delay action millisceonds
 * @param {number} ms - milliseconds to delay
 * @return {Promise} promise to complete in ms
 */
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Detail view
 */
class Detail {
  /**
   * constructor
   */
  constructor() {
    this.galleryData = null;
    this.element = document.createElement('div');
    this.element.classList.add('pg-detail');
    this.element.innerHTML = detailTemplate;
    const el = this.element; // short cut
    this.bgEl = el.querySelector('.back-cover');
    this.transImgEl = el.querySelector('.transition-img');

    this.sliderEl = el.querySelector('.slider');
    this.sliderPrevEl = el.querySelector('.slider .prev');
    this.sliderPrevImgEl = el.querySelector('.slider .prev img');
    this.sliderCurrentEl = el.querySelector('.slider .current');
    this.sliderCurrentImgEl = el.querySelector('.slider .current img');
    this.sliderNextEl = el.querySelector('.slider .next');
    this.sliderNextImgEl = el.querySelector('.slider .next img');

    this.titleEl = el.querySelector('header .title');
    this.closeEl = el.querySelector('header .close');

    this.bgEl.addEventListener('click', this.toggleTools.bind(this));
    this.closeEl.addEventListener('click', this.doClose.bind(this));
    this.sliderEl.addEventListener('pointerdown', this.sliderDown.bind(this));
    this.sliderEl.addEventListener('pointerup', this.sliderEnd.bind(this));
    this.sliderEl.addEventListener('pointermove', this.sliderMove.bind(this));
    this.sliderEl.addEventListener('pointercancel', this.sliderEnd.bind(this));
    // slider actions
    this.sliderPointerId = null;
    this.sliderDownTime = 0;
    this.sliderStartPosition = {x: 0, y: 0};
    this.sliderAnimTime = 0;
    this.sliderStart = 0;
    this.sliderTarget = 0;
    this.sliderEl.style.transform = 'translateX(0px)';
    // paging info
    this.currentIndex = -1;
    this.toolsActive = false;
    // transition properties
    this.state = 'settled';
    this.transitionStart = 0;
    this.targetPosition = {};
    this.startPosition = {};
    this.returnPositionProvider = null;
  }
  /**
   * setData from JSON object
   * @param {object} data - data to setup the grid with
   */
  setData(data) {
    this.galleryData = data;
  }
  /**
   * message when element is selected
   * @param {Element} selectedElement - img element selected
   */
  elementSelected(selectedElement) {
    this.element.classList.add('active');
    this.element.classList.add('tools-visible');
    this.element.classList.add('bg-visible');
    this.state = 'entering';
    this.toolsActive = true;
    const position = selectedElement.getBoundingClientRect();

    this.startPosition = {width: position.width,
      height: position.height,
      top: position.top,
      left: position.left,
    };
    const {width, height} = this.startPosition;
    const fitHight = window.innerHeight - HEADER_SIZE - FOOTER_SIZE;
    const fitWidth = window.innerWidth;
    const fitAspect = fitWidth / fitHight;
    const aspect = width / height;
    const natWidth = selectedElement.naturalWidth;
    const natHeight = selectedElement.naturalHeight;
    const workWidth = Math.min(fitWidth, natWidth);
    const workHeight = Math.min(fitHight, natHeight);

    const iAspect = height / width;
    if ( aspect >= fitAspect) {
      this.targetPosition = {
        width: workWidth,
        height: workWidth * iAspect,
        left: Math.max(0, fitWidth - workWidth) * 0.5,
        top: (fitHight - (workWidth * iAspect)) * 0.5 + HEADER_SIZE,
      };
    } else {
      this.targetPosition = {
        width: workHeight * aspect,
        height: workHeight,
        left: (fitWidth - workHeight * aspect) * 0.5,
        top: HEADER_SIZE,
      };
    }

    for (const key in this.startPosition) {
      if ({}.hasOwnProperty.call(this.startPosition, key)) {
        this.transImgEl.style[key] = this.startPosition[key];
      }
    }
    this.transImgEl.style.opacity = 1;
    this.transImgEl.setAttribute('src', selectedElement.src);
    const id = selectedElement.dataset.id;
    const itemData = this.galleryData.images.find((item) => item.id === id);
    this.currentIndex = this.galleryData.images.indexOf(itemData);

    this.setSliderFromIndex(this.currentIndex);

    this.transitionStart = Date.now();
    this.update();
  }
  /**
   * close handler
   */
  doClose() {
    if (this.state != 'settled') {
      return;
    }
    const currentData = this.galleryData.images[this.currentIndex];
    const targetEl = this.returnPositionProvider(currentData.id);
    // delay slightly to let scroll happen on iOS
    delay(200).then(()=>{
      const toBounds = targetEl.getBoundingClientRect();

      const fromElement = this.sliderCurrentImgEl;
      const fromBounds = fromElement.getBoundingClientRect();

      this.startPosition = {
        width: fromBounds.width,
        height: fromBounds.height,
        top: fromBounds.top,
        left: fromBounds.left,
      };
      this.targetPosition = {
        width: toBounds.width,
        height: toBounds.height,
        top: toBounds.top,
        left: toBounds.left,
      };
      this.element.classList.remove('tools-visible');
      this.element.classList.remove('bg-visible');
      this.state = 'exiting';
      this.sliderEl.style.opacity = 0;
      this.transImgEl.style.opacity = 1;
      this.transitionStart = Date.now();
      this.update();
    });
  }
  /**
   * toggle the tools and header on/off
   */
  toggleTools() {
    this.element.classList.toggle('tools-visible');
  }
  /**
   * update the transition state form the current image
   */
  setTransFromCurrent() {
    const itemData = this.galleryData.images[this.currentIndex];
    this.transImgEl.setAttribute('src', itemData.url);
    const currentBounds = this.sliderCurrentImgEl.getBoundingClientRect();
    this.transImgEl.style.width = currentBounds.width;
    this.transImgEl.style.height = currentBounds.height;
    this.transImgEl.style.top = currentBounds.top;
    this.transImgEl.style.left = currentBounds.left;
  }
  /**
   * set the slider slots base on an index
   * @param {number} index - index in the gallery data
   */
  setSliderFromIndex(index) {
    this.currentIndex = index;
    const itemData = this.galleryData.images[this.currentIndex];
    this.titleEl.innerText = itemData.title;
    this.sliderCurrentImgEl.setAttribute('src', itemData.url);
    const nextIndex = (this.currentIndex + 1) % this.galleryData.images.length;
    const prevIndex = (this.currentIndex - 1 + this.galleryData.images.length) %
      this.galleryData.images.length;
    const nextData = this.galleryData.images[nextIndex];
    const prevData = this.galleryData.images[prevIndex];
    this.sliderNextImgEl.setAttribute('src', nextData.url);
    this.sliderPrevImgEl.setAttribute('src', prevData.url);
    this.sliderEl.style.transform = 'translateX(0px)';
    this.setTransFromCurrent();
  }
  /**
   * rarange element in slider
   * @param {number} dir - positive to move forward negative to move back
   */
  swapSliderDirection(dir) {
    this.sliderEl.style.transform = 'translateX(0px)';
    const galleryLength = this.galleryData.images.length;
    // swap around elements just resetting element as in
    // setSliderFromIndex causes flashing on iOS
    if (dir > 0) {
      this.sliderCurrentEl.appendChild(this.sliderNextImgEl);
      this.sliderPrevEl.appendChild(this.sliderCurrentImgEl);
      this.sliderNextEl.appendChild(this.sliderPrevImgEl);
      this.currentIndex = (this.currentIndex + 1) %
        galleryLength;
      const nextIndex = (this.currentIndex + 1) %
        galleryLength;
      const nextData = this.galleryData.images[nextIndex];
      this.sliderPrevImgEl.setAttribute('src', nextData.url);
    } else {
      this.sliderCurrentEl.appendChild(this.sliderPrevImgEl);
      this.sliderNextEl.appendChild(this.sliderCurrentImgEl);
      this.sliderPrevEl.appendChild(this.sliderNextImgEl);
      this.currentIndex = (this.currentIndex - 1 + galleryLength) %
        galleryLength;
      const prevIndex = (this.currentIndex - 1 + galleryLength) %
        galleryLength;
      const prevData = this.galleryData.images[prevIndex];
      this.sliderNextImgEl.setAttribute('src', prevData.url);
    }
    const el = this.element; // short cut
    this.sliderPrevImgEl = el.querySelector('.slider .prev img');
    this.sliderCurrentImgEl = el.querySelector('.slider .current img');
    this.sliderNextImgEl = el.querySelector('.slider .next img');

    const itemData = this.galleryData.images[this.currentIndex];
    this.titleEl.innerText = itemData.title;
    this.setTransFromCurrent();
  }
  /**
   * slider handler
   * @param {PointerEvent} event - pointer event
   */
  sliderDown(event) {
    if (this.state === 'settled') {
      this.sliderPointerId = event.pointerId;
      this.sliderStartPosition = {x: event.x, y: event.y};
      this.sliderDownTime = Date.now();
      this.sliderEl.setPointerCapture(this.sliderPointerId);
    }
  }
  /**
   * slider handler
   * @param {PointerEvent} event - pointer event
   */
  sliderEnd(event) {
    if (this.sliderPointerId === event.pointerId) {
      this.sliderPointerId = -1;
      const delta = event.x - this.sliderStartPosition.x;
      const absDelta = Math.abs(delta);
      const width = window.innerWidth;
      const extract = /translateX\((-?\d+)px\)/g;
      const numeric = extract.exec(this.sliderEl.style.transform)[1];
      this.sliderStart = parseInt(numeric, 10);
      let doTransition = true;

      if (delta < width * -0.5 || delta > width * 0.5 ||
        (Date.now() - this.sliderDownTime < FLICK_TIME_THRESH &&
        absDelta > FLICK_MOVE_THRESH)) {
        const sign = delta < 0 ? -1 : 1;
        this.sliderTarget = width * sign;
      } else {
        this.sliderTarget = 0;
      }
      // check for tap
      if (absDelta < 10 &&
        Date.now() - this.sliderDownTime < FLICK_TIME_THRESH) {
        if (this.sliderStartPosition.x < width * 0.2) {
          this.sliderTarget = width;
        } else if (this.sliderStartPosition.x > width * 0.8) {
          this.sliderTarget = -width;
        } else {
          doTransition = false;
          this.toggleTools();
        }
      }
      // start animation if needed
      if (doTransition) {
        this.state = 'slide-settle';
        this.sliderAnimTime = Date.now();
        this.sliderUpdate();
      }
    }
  }
  /**
   * slider handler
   * @param {PointerEvent} event - pointer event
   */
  sliderMove(event) {
    event.preventDefault();
    if (this.state === 'settled' && this.sliderPointerId === event.pointerId) {
      event.preventDefault();
      const delta = event.x - this.sliderStartPosition.x;
      this.sliderEl.style.transform = `translateX(${delta}px)`;
    }
  }
  /**
   * update loop
   */
  update() {
    let t = (Date.now() - this.transitionStart) / TRANSITION_TIME;
    t = Math.min(t, 1);
    t = quadIn(t);
    for (const key in this.startPosition) {
      if ({}.hasOwnProperty.call(this.startPosition, key)) {
        this.transImgEl.style[key] = lerp(this.startPosition[key],
            this.targetPosition[key], t);
      }
    }
    if (t < 1) {
      window.requestAnimationFrame(()=> {
        this.update();
      });
    } else {
      if (this.state === 'exiting') {
        this.element.classList.remove('active');
        this.transImgEl.style.opacity = 0;
        this.state = 'settled';
      } else if (this.state === 'entering') {
        this.sliderEl.style.opacity = 1;
        this.transImgEl.style.opacity = 0;
        this.state = 'settled';
      }
    }
  }
  /**
   * slider animation update loop
   */
  sliderUpdate() {
    let t = (Date.now() - this.sliderAnimTime) / SLIDER_SETTLE_TIME;
    t = Math.min(t, 1);
    t = quadIn(t);
    const position = lerp(this.sliderStart, this.sliderTarget, t);
    this.sliderEl.style.transform = `translateX(${position}px)`;
    // weird thing where raf didn't seem to fire if there were
    // no dom updates
    this.titleEl.innerText = this.titleEl.innerText;
    if (t < 1) {
      window.requestAnimationFrame(()=> {
        this.sliderUpdate();
      });
    } else {
      this.state = 'settled';
      if (this.sliderTarget != 0) {
        const sign = this.sliderStart < this.sliderTarget ? -1 : 1;
        this.swapSliderDirection(sign);
      }
    }
  }
}

export {Detail};
