import 'babel-polyfill';
import '../scss/main.scss';
import {Application} from './app';

const baseEl = document.querySelector('#app');
// eslint-disable-next-line no-unused-vars
const app = new Application(baseEl);

fetch('/data/gallery-001.json').then((response)=>{
  return response.json();
}).then((json)=>{
  app.setData(json);
});

