import {Grid} from './ui/grid.js';
import {Detail} from './ui/detail.js';
/**
 * base application class
 */
class Application {
  /**
  * constructor
  * @param {!Element} baseElement base element for the application to attach to
  */
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.baseElement.classList.add('pg');
    this.grid = new Grid();
    this.baseElement.appendChild(this.grid.element);
    this.detail = new Detail();
    this.baseElement.appendChild(this.detail.element);
    this.grid.addSelectHandler((e)=>{
      this.detail.elementSelected(e);
    });
    this.detail.returnPositionProvider = (id)=>{
      return this.grid.positionItemInView(id);
    };
  }
  /**
   * set the data for the photo viewer
   * @param {object} data json object for gallery
   */
  setData(data) {
    this.grid.setData(data);
    this.detail.setData(data);
  }
}

export {Application};
