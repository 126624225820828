import gridTemplate from './grid.template.html';
import {HEADER_SIZE} from './constants.js';
/**
 * Grid component and logic
 */
class Grid {
  /**
   * constructor creates default grid handler
   */
  constructor() {
    this.selectHandlers = [];
    this.galleryData = null;
    this.element = document.createElement('div');
    this.element.classList.add('pg-grid');
    this.element.innerHTML = gridTemplate;
    this.headerEl = this.element.querySelector('header h2');
    this.gridEl = this.element.querySelector('.grid');
    this.gridEl.addEventListener('click', (e)=>{
      this.itemSelected(e);
    });
  }
  /**
   * setData from JSON object
   * @param {object} data - data to setup the grid with
   */
  setData(data) {
    this.galleryData = data;
    this.headerEl.innerText = this.galleryData.title;
    // TODO clear out old data
    for ( const item of this.galleryData.images) {
      this.createGridItem(item);
    }
  }
  /**
   * create a new grid item
   * @param {object} itemData - object containing title and url
   */
  createGridItem(itemData) {
    const {title, url, id} = itemData;
    const base = document.createElement('div');
    const img = document.createElement('img');
    base.appendChild(img);
    base.classList.add('grid-item');
    img.setAttribute('src', url);
    img.setAttribute('alt', title);
    img.dataset.id = id;
    this.gridEl.appendChild(base);
  }
  /**
   * select an item
   * @param {MouseEvent} event - mouse event
   */
  itemSelected(event) {
    if (event.target.matches('.pg-grid .grid-item img') &&
        'id' in event.target.dataset) {
      this.selectHandlers.forEach((handler)=>{
        handler(event.target);
      });
    }
  }

  /**
   * add select event handler
   * @param {function} handler - handler to call on item selected
   */
  addSelectHandler(handler) {
    this.selectHandlers.push(handler);
  }
  /**
   * position gallery item into view
   * @param {string} id - id of item to position into view
   * @return {Element} - the element positioned into view
   */
  positionItemInView(id) {
    const element = this.gridEl.querySelector(`img[data-id="${id}"]`);
    const viewHeight = window.innerHeight;
    const bounds = element.getBoundingClientRect();

    if (bounds.top < HEADER_SIZE ||
        bounds.top > viewHeight ||
        bounds.bottom > viewHeight) {
      const topRelPage = bounds.top + window.pageYOffset;
      const targetTop = HEADER_SIZE + (viewHeight - bounds.height) * 0.5;
      window.scrollTo(0, topRelPage - targetTop);
    }
    return element;
  }
}

export {Grid};
